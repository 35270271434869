import React from 'react';
import PropTypes from 'prop-types';
import utils from 'src/utils/utils';
import FilterType from 'src/enums/parameterType';
import FKValue from './FKUsedFilterValue';
import DefaultValue from './DefaultUsedFilterValue';

const UsedFilterValue = ({ filter, filtersByKey }) => {
  switch (filter.typeId) {
    case FilterType.IFOREIGN_KEY:
    case FilterType.TFOREIGN_KEY:
    case FilterType.N_FOREIGN_KEY:
    case FilterType.SINGLE_IFOREIGN_KEY:
      return <FKValue filter={filter} filtersByKey={filtersByKey} />;
    case FilterType.ENUM:
    case FilterType.ENUM_TEXT:
      return (
        <FKValue
          filter={filter}
          filtersByKey={filtersByKey}
          data={utils.getDataListFromEnum(filtersByKey[filter.key].enumName).reduce(
            (map, dataItem) => ({
              ...map,
              [dataItem.id]: dataItem.name,
            }),
            {}
          )}
        />
      );
    default:
      return <DefaultValue filter={filter} filtersByKey={filtersByKey} />;
  }
};
UsedFilterValue.propTypes = {
  filter: PropTypes.object.isRequired,
  filtersByKey: PropTypes.object.isRequired,
};

export default UsedFilterValue;
