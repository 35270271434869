import React from 'react';
import { connect, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import { Button, Typography, AppBar, Toolbar, IconButton } from '@mui/material';
import PreviousIcon from '@mui/icons-material/ChevronLeft';
import { saveParams } from 'src/reducers/parameters';
import utils from 'src/utils/utils';
import { useLocation, useRoute } from 'wouter';
import paramSelectors from 'src/selectors/parameters';

const useStyles = makeStyles()(theme => ({
  button: {
    margin: theme.spacing(0.5),
  },
  backButton: { color: theme.palette.primary.contrastText },
}));

const ParametersAppBar = ({ doSaveParams, isValid, multiParams }) => {
  const { classes } = useStyles();
  const [, navigate] = useLocation();
  const [matchListRoute] = useRoute('/params/list*?');
  const [matchEditRoute] = useRoute('/params/edit/*?');
  const currentParams = useSelector(paramSelectors.getCurrentParams);

  return (
    <AppBar
      style={{
        position: 'relative',
      }}
      color="primary"
    >
      <Toolbar style={{ paddingLeft: multiParams && matchEditRoute ? '8px' : '16px' }}>
        {multiParams && matchEditRoute && (
          <IconButton
            aria-label="clear"
            onClick={e => {
              e.preventDefault();
              navigate('/params/list');
            }}
            className={classes.backButton}
            size="large"
          >
            <PreviousIcon />
          </IconButton>
        )}
        {matchEditRoute && (
          <Typography variant="h5" color="inherit">
            {(currentParams.clubName === 'default' &&
              ((multiParams && utils.getLang('smartmessaging.config.appBarTitle.default')) ||
                utils.getLang('smartmessaging.config.appBarTitle.singleClub'))) ||
              utils.stringFormat(utils.getLang('smartmessaging.config.appBarTitle'), [
                currentParams.clubName,
              ])}
          </Typography>
        )}
        {matchListRoute && (
          <Typography variant="h5" color="inherit">
            {utils.getLang('smartmessaging.config.appBarTitle.paramsList')}
          </Typography>
        )}
        {matchEditRoute && (
          <Button
            style={{ marginLeft: 'auto' }}
            disabled={!isValid}
            variant="text"
            color="secondary"
            className={classes.button}
            onClick={e => {
              e.preventDefault();
              doSaveParams();
            }}
          >
            {utils.getLang('smartmessaging.config.save')}
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

ParametersAppBar.propTypes = {
  isValid: PropTypes.bool.isRequired,
  doSaveParams: PropTypes.func.isRequired,
  multiParams: PropTypes.bool.isRequired,
  currentClubId: PropTypes.string,
};

ParametersAppBar.defaultProps = {
  currentClubId: null,
};

const mapStateToProps = state => ({
  multiParams: Object.keys(state.parameters.clubParams).length > 1,
  isValid: !Object.values(state.parameters.invalidities).length,
});

const actionCreators = {
  doSaveParams: saveParams,
};

export default connect(mapStateToProps, actionCreators)(ParametersAppBar);
