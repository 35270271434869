export default {
  TEXT: 1,
  NUMBER: 2,
  INTEGER: 3,
  DATE: 4,
  IFOREIGN_KEY: 5,
  BOOLEAN: 6,
  GROUP: 7,
  ENUM: 8,
  N_FOREIGN_KEY: 9,
  LINKED_GROUP: 10,
  LINKED: 11,
  CLUBS: 12,
  TFOREIGN_KEY: 13,
  ENUM_TEXT: 14,
  SINGLE_IFOREIGN_KEY: 15,
};
